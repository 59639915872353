import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout.js"
import SEO from "../components/seo"
import { Row, Col } from "react-bootstrap"
import Img from "gatsby-image"


const IndexPage = ({ data }) => {
  return (
  <Layout>
    <SEO
      title={data.wordpressPage.title}
      description={data.wordpressPage.excerpt}
    />
    <Row className="">
      <Col md={12} lg={10}>
        <div dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }} />
      </Col>
        {data.wordpressPage.slug !== 'verein' ? (
          ``
        ) : (
          <Col sm={12}>
            <Row className="align-items-end">
              <Col sm={4}>
                <Img fluid={data.wordpressPage.acf.image.localFile.childImageSharp.fluid} alt="flatterschafft building" />
              </Col>
              <Col className="" sm={8}>
                <div className="caption" dangerouslySetInnerHTML={{__html: data.wordpressPage.acf.image.caption}}></div>
              </Col>
            </Row>
        </Col> 
        )}
    </Row>
  </Layout>
)}
export default IndexPage

export const query = graphql`
  query {
    wordpressPage (slug: {eq: "verein"}) {
      title
      excerpt
      content
      slug
      id
      acf {
        image {
          id
          caption
           localFile {
              childImageSharp {
                id
                # Try editing the "maxWidth" value to generate resized images.
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`